import React, { useContext, useEffect, useState } from "react";
import Header from "../../../Home/Header";
import VisaContext from "../../../../context/visa-context";

const CheckGroupVisa = () => {
  const ctxData = useContext(VisaContext);

  return (
    <>
      <div className="ViaApply_Header dashboard_header">
        <Header />
      </div>
      <div className="dashboard_heading">
        <main className="main-container">
          <div className="main-title11">
            <h3 style={{ color: "#13e313", fontSize: "25px" }}>
              Total Work Permit Applications
            </h3>
          </div>
          <div className="userpage">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Last Name</th>
                  <th>Destination</th>
                  <th>Price</th>
                  <th>Apply Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {ctxData.groupVisaId.length > 0 ? (
                  ctxData.groupVisaId.map((datas, ind) => (
                    <tr key={ind}>
                      <td>{datas.workVisa.name}</td>
                      <td>{datas.workVisa.lname}</td>
                      <td>{datas.workVisa.countryName}</td>
                      <td>{datas.workVisa.countryPrice}</td>
                      <td>{datas.workVisa.ApplyDate}</td>
                      <td className="status_data">{datas.workVisa.status && datas.workVisa.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </>
  );
};

export default CheckGroupVisa;
