import React, { useEffect, useState } from "react";
import Header from "../../../../../Home/Header";
import CardMedia from "@mui/material/CardMedia";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { Link } from "react-router-dom";
import "./groupvisa.css";
import { WorkPermitCountries } from "../WorkPermitDatabase";
import { Helmet } from "react-helmet";

const GroupCountryCards = () => {
  const [updatedCountries, setUpdatedCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [threeDays, setThreeDays] = useState(' ');
  // console.log("setThreeDays", threeDays)

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };
  //******************* */ Date Every Months  *************************
  useEffect(() => {
    const updateDates = () => {
      const today = new Date();

      const updatedData = WorkPermitCountries.map((country) => {
        let newDate = new Date(country.dates);

        // Update the date to the next valid month
        while (today >= newDate) {
          newDate.setMonth(newDate.getMonth() + 1);
        }

        // Calculate 3 days before the updated date
        let threeDaysBefore = new Date(newDate);
        threeDaysBefore.setDate(newDate.getDate() - 3);

        return {
          ...country,
          dates: newDate,
          threeDays: threeDaysBefore, // Update threeDays field to reflect the date 3 days before
        };
      });

      setUpdatedCountries(updatedData);
      setLoading(false); // Stop loading when data is ready
    };

    updateDates();
    const interval = setInterval(updateDates, 24 * 60 * 60 * 1000); // Daily update

    return () => clearInterval(interval);
  }, []);

  const currentUrl = window.location.href;
  return (
    <>
      <div className="ViaApply_Header dashboard_header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Countries</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>

      <div className="Country_UnivercityCards mainwraper">
        <div className="container">
          <div className="Univercity_CardsPage_heading">
            <h2>Countries</h2>
          </div>
          <div className="row">
            {updatedCountries.map((Cards, index) => (
              <div className="col-lg-4 col-md-6  " key={Cards.id}>
                <Link to={`/startApplication/${Cards.id}`} id="myButton">
                  <div className="card">
                    <div className="card-img">
                      <h3 className="img-content">
                        Next Filing Date {"  "}
                        <span className="DestinatioCont55">
                          {formatDate(Cards.dates)}
                        </span>
                      </h3>
                      <CardMedia
                        image={Cards.pic}
                        component="img"
                        loading="lazy"
                        alt={` UAE Work-Permit Image `}
                      />
                        <h2 className="img-contenth2">
                        Deadline {"  "}
                        <span className="DestinatioCont55">
                          {formatDate(Cards.threeDays)}
                        </span>
                      </h2>
                    </div>
                    <div className="card_content">
                      <div className="country">{Cards.name} </div>
                      {/* <div className="job_position">
                        <h6>Position</h6>
                        <p>{Cards.Job}</p>
                      </div> */}
                      <div className="price">
                        <p>Price</p>
                        <MdOutlineCurrencyRupee /> {Cards.price}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupCountryCards;
