import React, { useContext, useEffect } from "react";
import "../../userDashboard.css";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Header from "../../../Home/Header";
import VisaContext from "../../../../context/visa-context";
import { GoFileSubmodule } from "react-icons/go";
import { FaCcVisa } from "react-icons/fa6";
import { FaMagnifyingGlassArrowRight } from "react-icons/fa6";
import { HiOutlineDownload } from "react-icons/hi";

const Dashboard = () => {
  const userId = localStorage.getItem("userId");
  const ctxApi = useContext(VisaContext);
  const visasFiles = ctxApi.visasFiles;
  const ctxData = useContext(VisaContext);

  // **********************👍*********************
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  // *************👍*********
  return (
    <>
      <div className="ViaApply_Header dashboard_header">
        <Header />
      </div>
      <div className="container">
        <div className="main-container">
          <div className="main-title">
            <h3>DASHBOARD</h3>
          </div>

          <h3
            style={{ textAlign: "center", margin: "1rem 0rem", color: "#07078b" }}
          >
            Tourist Visa
          </h3>
          <div className="main-cards">
            <Link to={"/"}>
              <div className="card_dashboard">
                <div className="card_dashboard-inner">
                  <h3 style={{ color: "white" }}>Tourist Applications</h3>

                  <FaMagnifyingGlassArrowRight className="card_dashboard_icon" />
                </div>
              </div>
            </Link>
            <Link to={"/User-Applications"}>
              <div className="card_dashboard">
                <div className="card_dashboard-inner">
                  <h3 style={{ color: "white" }}>Check Applications</h3>
                  <BsFillGrid3X3GapFill className=" card_dashboard_icon" />
                </div>
                <h1 style={{ color: "white" }}>
                  {" "}
                  {ctxData.applications ? ctxData.applications.length : 0}{" "}
                </h1>
              </div>
            </Link>
            <Link to={"/visasFiles"}>
              <div className="card_dashboard">
                <div className="card_dashboard-inner">
                  <h3 style={{ color: "white" }}>Download Visa</h3>
                  <GoFileSubmodule className="card_dashboard_icon" />
                </div>
                <h1 style={{ color: "white" }}> {visasFiles.length} </h1>
              </div>
            </Link>
          </div>

          {/* ***************************Work Permit Visa****************************** */}
          <h3
            style={{ textAlign: "center", margin: "1rem 0rem", color: "#07078b" }}
          >
            Group Visa Application
          </h3>
          <div className="main-cards">
            <Link to={"/group-visa-counrty"}>
              <div className="card_dashboard">
                <div className="card_dashboard-inner">
                  <h3 style={{ color: "white" }}>Group Visa Applications </h3>

                  <FaMagnifyingGlassArrowRight className="card_dashboard_icon" />
                </div>
                <h1> </h1>
              </div>
            </Link>
            <Link to={"/check-group-visa"}>
              <div className="card_dashboard">
                <div className="card_dashboard-inner">
                  <h3 style={{ color: "white" }}>Check Group Applications</h3>
                  <BsFillGrid3X3GapFill className=" card_dashboard_icon" />
                </div>
                <h1 style={{ color: "white" }}>
                  {" "}
                  {ctxData.workVisas ? ctxData.workVisas.length : 0}{" "}
                </h1>
              </div>
            </Link>
            <Link to={"/downloadgroupApplications"}>
              <div className="card_dashboard">
                <div className="card_dashboard-inner">
                  <h3 style={{ color: "white" }}>
                    Download Group Applications
                  </h3>
                  <HiOutlineDownload className="card_dashboard_icon" />
                </div>
                <h1 style={{ color: "white" }}>
                  {" "}
                  {/* {ctxData.workVisas ? ctxData.workVisas.length : 0}{" "} */}
                </h1>
              </div>
            </Link>
          </div>
          {/* ***************************Book Add Onns****************************** */}
          <h3
            style={{ textAlign: "center", margin: "1rem 0rem", color: "#07078b" }}
          >
            Travel Add Ons
          </h3>
          <div className="main-cards">
            <Link to={"/addonns"}>
              <div className="card_dashboard">
                <div className="card_dashboard-inner">
                  <h3 style={{ color: "white" }}>Travel Add Ons</h3>
                  <FaMagnifyingGlassArrowRight className="card_dashboard_icon" />
                </div>
                <h1> </h1>
              </div>
            </Link>
            <Link to={"/checkAddonns"}>
              <div className="card_dashboard">
                <div className="card_dashboard-inner">
                  <h3 style={{ color: "white" }}>Check AddOns File</h3>
                  <BsFillGrid3X3GapFill className=" card_dashboard_icon" />
                </div>
                <h1 style={{ color: "white" }}>
                  {" "}
                  {/* {ctxData.addOnsFiles ? ctxData.addOnsFiles.length : 0}{" "} */}
                </h1>
              </div>
            </Link>
            <Link to={"/AddOns-DownloadFile"}>
              <div className="card_dashboard">
                <div className="card_dashboard-inner">
                  <h3 style={{ color: "white" }}>Download AddOns File</h3>
                  <HiOutlineDownload className="card_dashboard_icon" />
                </div>
                <h1 style={{ color: "white" }}>
                  {" "}
                  {ctxData.addOnsFiles ? ctxData.addOnsFiles.length : 0}{" "}
                </h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
