import React, { useContext, useEffect, useState } from "react";
import Header from "../../../../../Home/Header";
// // Strper
import Stepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import { Helmet } from "react-helmet";
import { Form, useLocation } from "react-router-dom";
import axios from "axios";
import { BsCurrencyRupee } from "react-icons/bs";
import { FormCheck } from "react-bootstrap";
import VisaContext from "../../../../../../context/visa-context";

const ApplicantCheckOutPage = () => {
  // addons state management 💯 💯
  const [dummyPrice, setDummyPrice] = useState(499);
  const [fDummyPrice, setFDummyPrice] = useState(999);
  const [isOneWay, setIsOneWay] = useState(true); // One-Way is selected by default
  const [countryPrice, setCountryPrice] = useState(0);
  const [addOnPrices, setAddOnPrices] = useState({
    flight: 0,
    hotel: 0,
    itinerary: 0,
    coverLetter: 0,
  });
  const [addedAddOns, setAddedAddOns] = useState({
    flight: false,
    hotel: false,
    itinerary: false,
    coverLetter: false,
  });
  // const [message, setMessage] = useState("");
  const [activeAddOns, setActiveAddOns] = useState([]);
  const [user, setUser] = useState("");

  const userId = localStorage.getItem("userId");
  const context = useContext(VisaContext);
  // const travelerIds = JSON.parse(localStorage.getItem("travelerIds")) || [];
  const Usres = context?.users || []; // Safeguard for undefined `ctx.users`

  // ***********Show user name***************
  useEffect(() => {
    if (Usres && Usres.length > 0 && userId) { // Ensure `Usres` is valid and not empty
      const RegisterUsers = Usres.filter((app) => app._id === userId);
      if (RegisterUsers.length > 0) {
        const UName = RegisterUsers[0];
        const CName = `${UName.name || ""} ${UName.lname || ""}`.trim(); // Safeguard for missing values
        setUser(CName);
      } else {
        setUser(null); // Fallback if no matching user is found
      }
    }
  }, [Usres, userId]);

  console.log("user",user)

  const loc = useLocation();
  const { state } = loc;
  const { formData = [], country = {} } = state || {};

  // PhonePay Payment gateway 💯 👍 💯 👍
  // const VisaPrice = updatedAmount !== null ? updatedAmount : countryPrice;
  const number = formData.length;
  const rupee = country.price * number;
  const NewVisaPrice = parseInt((countryPrice * 3) / 100);
  const TotalAmount = countryPrice + NewVisaPrice;

  const data = {
    amount: parseInt(TotalAmount),
  };

  const handlePaymentGetway = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://backend-visa2.vercel.app/api/applicantPayment/applicant-Payment",
        { ...data }
      );

      if (res.data && res.data.data.instrumentResponse.redirectInfo.url) {
        window.location.href =
          res.data.data.instrumentResponse.redirectInfo.url;
      }
    } catch (error) {
      if (error.response) {
        console.error("Response error details:", error.response.data);
      } else {
        console.error("Error in payment gateway:", error.message);
      }
    }
  };
  const NewAddOnPrice = {
    flight:addOnPrices.flight/number,
    hotel:addOnPrices.hotel/number,
    itinerary:addOnPrices.itinerary/number,
    coverLetter:addOnPrices.coverLetter/number
  } 
  
  useEffect(() => {
    const workVisaId = JSON.parse(sessionStorage.getItem("workVisaId")) || [];

    // Apply date formatting
    const applyDate = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(new Date());

        // Time 📅 format 💯
        const now = new Date();

        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const formattedTime = `${hours}:${minutes}:${seconds}`;

    // Map workData to the desired structure
    const data = formData.map((applicant, index) => ({
      name: applicant.name,
      lname: applicant.lname,
      countryName: country.name, // Assuming country is an object
      countryPrice: TotalAmount, // Assuming country is an object
      ApplyDate: applyDate,
      addOnPrices:NewAddOnPrice,
      Cname: user,
      status:"Processing",
      Time:formattedTime,
      userId: userId,
      id: workVisaId[index],
    }));

    // Save data in sessionStorage
    const storedData = sessionStorage.getItem("workVisa");
    if (!storedData || storedData !== JSON.stringify(data)) {
      sessionStorage.setItem("workVisa", JSON.stringify(data));
    }
  }, [formData, country.name, country.price,NewAddOnPrice]);

   useEffect(() => {
      if (rupee) {
        // const initialPrice = Number(discountedPrice) * travelers?.length;
        setCountryPrice(rupee);
      }
    }, [ rupee]);

  const handleAddButtonClick = (addOnType, amount, event) => {
    event.preventDefault();
    const priceToAdd = formData?.length * amount;
    setCountryPrice((prevPrice) => prevPrice + priceToAdd);
    setAddOnPrices((prevState) => ({
      ...prevState,
      [addOnType]: prevState[addOnType] + priceToAdd,
    }));
    setAddedAddOns((prevState) => ({ ...prevState, [addOnType]: true }));
    setActiveAddOns((prevState) => [...prevState, addOnType]);
  };

  const handleRemoveButtonClick = (addOnType, amount, event) => {
    event.preventDefault();
    const priceToSubtract = formData?.length * amount;
    setCountryPrice((prevPrice) => prevPrice - priceToSubtract);
    setAddOnPrices((prevState) => ({
      ...prevState,
      [addOnType]: prevState[addOnType] - priceToSubtract,
    }));
    setAddedAddOns((prevState) => ({ ...prevState, [addOnType]: false }));
    setActiveAddOns((prevState) =>
      prevState.filter((addOn) => addOn !== addOnType)
    );
  };

  const handleOneWaySelect = () => {
    setFDummyPrice(999);
    setIsOneWay(true); // Set One-Way as selected
  };

  const handleTwoWaySelect = () => {
    if (!isOneWay) {
      // If already on Two-Way, switch back to One-Way
      handleOneWaySelect();
    } else {
      setFDummyPrice(1998); // Set price for Two-Way
      setIsOneWay(false); // Set Two-Way as selected
    }
  };

  // PhonePay Payment gateway 💯 👍
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const currentUrl = window.location.href;
  return (
    <>
      <div className="mainwrapper main_checkoutPage">
        <div className="ViaApply_Header trevellerHead">
          <Header />
          <Helmet>
            <meta charSet="utf-8" />
            <title>Checkout</title>
            <meta property="og:url" content={currentUrl} />
            <meta property="og:type" content="website" />
          </Helmet>
        </div>
        <div className="container stepper0144">
          <Stepper size="sm">
            <Step
              indicator={
                <StepIndicator
                  variant="solid"
                  sx={{ backgroundColor: "red", color: "white" }}
                >
                  1
                </StepIndicator>
              }
            >
              Step 1
            </Step>
            <Step
              indicator={
                <StepIndicator
                  variant="solid"
                  sx={{ backgroundColor: "red", color: "white" }}
                >
                  2
                </StepIndicator>
              }
            >
              Step 2
            </Step>
            <Step
              indicator={
                <StepIndicator
                  variant="solid"
                  sx={{ backgroundColor: "red", color: "white" }}
                >
                  3
                </StepIndicator>
              }
            >
              Step 3
            </Step>
          </Stepper>
        </div>
        <div>
          {/* <div className="row"> */}
            <div className="addOnsFullPage">
              <div>
                <div className="FormMain">
                  <form className="mainformAdd">
                    <h2>Add Ons</h2>
                    <p>
                      No. Of Travellers{" "}
                      <span
                        style={{
                          color: "black",
                          fontWeight: "600",
                          marginLeft: "5px",
                        }}
                      >
                        {formData.length}{" "}
                      </span>
                    </p>
                    <div className="dummyfightaddonns">
                      <h5>
                        One-Way{" "}
                        <span>
                          <FormCheck
                            type="radio"
                            aria-label="radio"
                            checked={isOneWay}
                            onChange={handleOneWaySelect}
                          />
                        </span>
                      </h5>
                      <h5>
                        Two-Way{" "}
                        <span>
                          <FormCheck
                            type="radio"
                            aria-label="radio 1"
                            checked={!isOneWay}
                            onChange={handleTwoWaySelect}
                          />
                        </span>
                      </h5>
                    </div>
                    <div className="DummyFlightTicket">
                      <h5 className="flight_h5">
                        Flight Ticket (Live PNR){" "}
                        <strong>
                          <BsCurrencyRupee /> {fDummyPrice * formData?.length}
                        </strong>{" "}
                      </h5>
                      <button
                        onClick={(event) =>
                          handleAddButtonClick("flight", fDummyPrice, event)
                        }
                        disabled={addedAddOns.flight}
                        style={addedAddOns.flight ? { display: "none" } : {}}
                      >
                        Add
                      </button>
                      <button
                        onClick={(event) =>
                          handleRemoveButtonClick("flight", fDummyPrice, event)
                        }
                        disabled={!addedAddOns.flight}
                        style={
                          !addedAddOns.flight
                            ? { display: "none" }
                            : { backgroundColor: "blue", color: "white" }
                        }
                      >
                        Remove
                      </button>
                    </div>
                    <div className="DummyFlightTicket">
                      <h5>
                        Hotel Booking{" "}
                        <strong>
                          <BsCurrencyRupee /> {dummyPrice * formData?.length}
                        </strong>{" "}
                      </h5>
                      <button
                        onClick={(event) =>
                          handleAddButtonClick("hotel", dummyPrice, event)
                        }
                        disabled={addedAddOns.hotel}
                        style={addedAddOns.hotel ? { display: "none" } : {}}
                      >
                        Add
                      </button>
                      <button
                        onClick={(event) =>
                          handleRemoveButtonClick("hotel", dummyPrice, event)
                        }
                        disabled={!addedAddOns.hotel}
                        style={
                          !addedAddOns.hotel
                            ? { display: "none" }
                            : { backgroundColor: "blue", color: "white" }
                        }
                      >
                        Remove
                      </button>
                    </div>
                    <div className="DummyFlightTicket">
                      <h5>
                        Itinerary{" "}
                        <strong>
                          <BsCurrencyRupee /> {dummyPrice * formData?.length}
                        </strong>{" "}
                      </h5>
                      <button
                        onClick={(event) =>
                          handleAddButtonClick("itinerary", dummyPrice, event)
                        }
                        disabled={addedAddOns.itinerary}
                        style={addedAddOns.itinerary ? { display: "none" } : {}}
                      >
                        Add
                      </button>
                      <button
                        onClick={(event) =>
                          handleRemoveButtonClick(
                            "itinerary",
                            dummyPrice,
                            event
                          )
                        }
                        disabled={!addedAddOns.itinerary}
                        style={
                          !addedAddOns.itinerary
                            ? { display: "none" }
                            : { backgroundColor: "blue", color: "white" }
                        }
                      >
                        Remove
                      </button>
                    </div>
                    <div className="DummyFlightTicket">
                      <h5>
                        Cover Letter{" "}
                        <strong>
                          <BsCurrencyRupee /> {dummyPrice * formData?.length}
                        </strong>{" "}
                      </h5>
                      <button
                        onClick={(event) =>
                          handleAddButtonClick("coverLetter", dummyPrice, event)
                        }
                        disabled={addedAddOns.coverLetter}
                        style={
                          addedAddOns.coverLetter ? { display: "none" } : {}
                        }
                      >
                        Add
                      </button>
                      <button
                        onClick={(event) =>
                          handleRemoveButtonClick(
                            "coverLetter",
                            dummyPrice,
                            event
                          )
                        }
                        disabled={!addedAddOns.coverLetter}
                        style={
                          !addedAddOns.coverLetter
                            ? { display: "none" }
                            : { backgroundColor: "blue", color: "white" }
                        }
                      >
                        Remove
                      </button>
                    </div>
                  </form>
                  <hr style={{ backgroundColor: "red", height: "2px" }} />
                  <p className="show addOns ">
                    {activeAddOns.map((addOn) => (
                      <span key={addOn} style={{ color: "red" }}>
                        {addOn.charAt(0).toUpperCase() + addOn.slice(1)} added ,{" "}
                      </span>
                    ))}
                  </p>
                </div>
              </div>

              <div className="ReviewPayment">
                <form className="mainformAdd">
                  <h2>Review Payment</h2>
                  <div>
                    {formData.map((Tdata, inde) => (
                      <div className="DummyFlightTicket">
                        <h5>
                          Applicant{" "}
                          <span
                            style={{
                              fontWeight: "500",
                              marginLeft: "5px",
                              color: "red",
                            }}
                          >
                            {inde + 1}
                          </span>{" "}
                        </h5>
                        <span className="DestinatioCont" key={inde}>
                          {Tdata.name} {Tdata.lname}{" "}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="DummyFlightTicket">
                    <h5>Destination</h5>
                    <span className="DestinatioCont">{country.name} </span>
                  </div>
                  {/* <div className="DummyFlightTicket">
                <h5>Total Price</h5>
                <span className="DestinatioCont">
                  {" "}
                  {rupee}                
                </span>
              </div> */}

                  <div className="DummyFlightTicket">
                    <h5>Visa Price</h5>
                    <span className="DestinatioCont"> {countryPrice}</span>
                  </div>
                  <div className="DummyFlightTicket">
                    <h5>Gateway charge 3%</h5>
                    <span className="DestinatioCont"> {NewVisaPrice}</span>
                  </div>
                  <div className="DummyFlightTicket">
                    <h5>Total Amount</h5>
                    <span className="DestinatioCont"> {TotalAmount}</span>
                  </div>
                </form>
                <div className="PaymentBtn">
                  <button className="btn" onClick={handlePaymentGetway}>
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default ApplicantCheckOutPage;
