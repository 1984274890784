import React, { useContext } from "react";
import VisaContext from "../../../../context/visa-context";
import MyLoader from "../../../Loader/MyLoader";
import Header from "../../../Home/Header";

const CheckAddons = () => {
  const ctxData = useContext(VisaContext);
  return (
    <>
      <div className="ViaApply_Header dashboard_header">
        <Header />
      </div>
      <div className="dashboard_heading">
        <main className="main-container">
          {ctxData.isLoading && <MyLoader />}
          <div className="main-title11">
            <h3 style={{ color: "#13e313", fontSize: "25px" }}>Check AddOns</h3>
          </div>
          <div className="userpage">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Destination</th>
                  <th>AddonType</th>
                </tr>
              </thead>
              <tbody>
                {ctxData.allAddons?.data?.length > 0 ? (
                  ctxData.allAddons.data.map((datas, ind) => (
                    <tr key={ind}>
                      <td>{datas.name}</td>
                      <td>
                        {datas.from
                          ? datas.from
                          : datas.cCity
                          ? datas.cCity
                          : ""}
                      </td>
                      <td>{datas.AoType}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </>
  );
};

export default CheckAddons;
