function dateHelper(days) {
  let date = new Date();
  date.setDate(date.getDate() + days);
  const options = { day: "numeric", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
}


export const WorkPermitCountries = [
  {
    id: 1,
    name: "United Kingdom",
    pic: "/NewImage/Uk-group-visa.jpg",
    price: "15000",
    dates: new Date(2024, 0, 7),
    threeDays:"",
    Vtype: "Sticker",
    sDuration: "180 Days",
    vValidity: "180 Days",
    pTime: "30 Working Days",
  
  },
  {
    id: 2,
    name: "Canada",
    pic: "/NewImage/Canada-groupVisa.webp",
    price: "13200",
    dates: new Date(2025, 0, 26),
    threeDays:"",
    Vtype: "Sticker",
    sDuration: "180 Days",
    vValidity: "10 year",
    pTime: "86 Working Days",
  },
  {
    id: 3,
    name: "New Zealand",
    pic: "/NewImage/NZ-group-visa.webp",
    price: "24500",
    dates: new Date(2025, 0, 14),
    threeDays:"",
    Vtype: "E-Visa",
    sDuration: "6 Months",
    vValidity: "18 Months",
    pTime: "25 Working Days",
  },
  {
    id: 4,
    name: "Australia",
    pic: "/NewImage/Australia-goup-visa.jpg",
    price: "13000",
    dates: new Date(2025, 0, 21),
    threeDays:"",
    Vtype: "E-Visa",
    sDuration: "90 Days",
    vValidity: "3 year",
    pTime: "20 Days",
  },

 
];
