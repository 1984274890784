import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import "../../../../../VisaApply/visaApply.css";
import Header from "../../../../../Home/Header";
import StartApplications from "./StartApplications.css"
import "react-datepicker/dist/react-datepicker.css";
import CardMedia from "@mui/material/CardMedia";
import { FaRupeeSign } from "react-icons/fa";
import Footer from "../../../../../Footer/Footer";
import { WorkPermitCountries } from "../WorkPermitDatabase";
// import VisaCalcultor from "./VisaCalculator.jsx";
import { Helmet } from "react-helmet";
import VisaContext from "../../../../../../context/visa-context";

const StartApplication = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [country, setCountry] = useState(null);
  const [filterCountry, setFilterCountry] = useState([]);
  const ctxData = useContext(VisaContext);

  useEffect(() => {
    const selectedCountry = WorkPermitCountries.find(
      (country) => country.id === parseFloat(id)
    );
    setCountry(selectedCountry);
  
    if (selectedCountry ) {
      const filtered = ctxData.groupVisa.filter(
        (item) =>
          item.workVisa.countryName &&
          selectedCountry.name &&
          item.workVisa.countryName.toLowerCase() === selectedCountry.name.toLowerCase()
      );
      setFilterCountry(filtered);
    }
  }, [id, ctxData.groupVisa]);

  
  const handleStartApplication = () => {
    navigate("/group-visa-details", {
      state: { country },
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const currentUrl = window.location.href;

  return (
    <>
      <div className="ViaApply_Header trevellerHead">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{country ? country.name : "Start-Application"}</title>
          <meta
            name="description"
            content="Need a visa? Apply for your visa with ease. Start your journey today!"
          />
          <meta
            name="keywords"
            content="work visa requirements, visa requirements by country, how to apply for a visa, tourist visa, work visa, student visa, and interview preparation.,"
          />
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </div>
      {country && (
        <div className="main-wrapper-visaPage">
          <div className="CountryName">{country.name}</div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="collumPadding">
                  <div className="card visaCard1">
                    <div className="card-img">
                      <h3 className="img-content">Application Recieved <span className="Group_application_recieved" > { filterCountry.length}</span></h3>
                      <CardMedia
                        image={process.env.PUBLIC_URL + country.pic}
                        component="img"
                        loading="lazy"
                      />
                    </div>
                  </div>

                  <div className="Accordian_Box">
                    <div className="visaCard_content">
                      <h4>Group Applications</h4>
                     <div className="userpage">
                      <table>
                        <thead>
                          <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Destination</th>
                          </tr>
                        </thead>
                        {filterCountry.map((data,ind)=>{
                        return (
                          <tbody>
                          <tr key={ind}>
                            <th>{data.workVisa.name}</th>
                            <th>{data.workVisa.lname}</th>
                            <th>{data.workVisa.countryName}</th>
                          </tr>
                        </tbody>
                        )
                      })}
                        
                      </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="collumPadding">
                  {/* *****************************Every Country cards************************ */}

                  <div className="visa_colorBox">
                    <div className="cost122">
                      {/* <h5>Your {displayNewText} in just </h5> */}
                      <h5>
                        <FaRupeeSign /> {country.price}
                      </h5>
                    </div>
                    <hr className="border_bottom01" />
                    <div className="visaapplyCards00">
                      <div className="visaapplyCards1">
                        <ul>
                          <li style={{ marginBottom: "3px" }}>Visa Types</li>
                          <li>Stay duration</li>
                          <li> Visa Validity</li>
                          <li> Processing time</li>
                        </ul>
                      </div>
                      <div className="visaapplyCards1">
                        <ul>
                          <li style={{ marginBottom: "3px" }}>
                            {country.Vtype}
                          </li>
                          <li>{country.sDuration} </li>
                          <li>{country.vValidity} </li>
                          <li>{country.pTime}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-primary btn_primarybtn"
                        onClick={handleStartApplication}
                      >
                        Start Application
                      </button>
                    </div>
                  </div>

                  {/* ****************Business Visa Cards********** */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default StartApplication;
