import React, { useContext, useEffect, useRef, useState } from "react";
import "./WorkDetails.css";
import { IoMdAddCircle } from "react-icons/io";
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { CiCircleRemove } from "react-icons/ci";
import Header from "../../../../../Home/Header";
// ********************************CALLENDAR**********************************
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// Strper
import Stepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
// **********
import TextField from "@mui/material/TextField";
import { Helmet } from "react-helmet";
import MyLoader from "../../../../../Loader/MyLoader";
import VisaContext from "../../../../../../context/visa-context";
import { useLocation, useNavigate } from "react-router-dom";
import { validationForApplicant } from "../../../../../../util/validation";
import { WorkPermitCountries } from "../WorkPermitDatabase";
// import {}

const GroupVisaDetails = () => {
  const [photos, setPhotos] = useState([]); // Initialize as an array
  const [passport, setPassport] = useState([]);
  const [otherDoc, setOtherDoc] = useState([]);
  const [updatedCountries, setUpdatedCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  // apply date********************************************************
  const applyDate = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(new Date());
  const [workData, setWorkData] = useState([
    {
      name: "",
      lname: "",
      email: "",
      MobileNO: "",
      dob: "",
      passportNo: "",
      ApplyDate: applyDate,
      photos: [],
      passport: [],
      otherDoc: [],
    },
  ]);

  const [count, setCount] = useState(1);
  const [errors, setErrors] = useState([]);
  const photoInputRefs = useRef([]);
  const passportInputRefs = useRef([]);
  const otherDocInputRefs = useRef([]);
  const navigate = useNavigate();
  const loc = useLocation();
  const { state } = loc;
  const { country = [] } = state || {};
  const ctx = useContext(VisaContext);

  const AddPersson = () => {
    setWorkData([
      ...workData,
      {
        name: "",
        lname: "",
        email: "",
        MobileNO: "",
        dob: "",
        passportNo: "",
        ApplyDate: applyDate,
        photos: [],
        passport: [],
        otherDoc: [],
      },
    ]);
    setCount(count + 1);
  };


  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }).format(date);
  };
  //******************* */ Date Every Months  *************************
  useEffect(() => {
    const updateDates = () => {
      const today = new Date();

      const updatedData = WorkPermitCountries.map((country) => {
        let newDate = new Date(country.dates);
        while (today >= newDate) {
          newDate.setMonth(newDate.getMonth() + 1);
        }
        return { ...country, dates: newDate };
      });
      setUpdatedCountries(updatedData);
      setLoading(false); // Stop loading when data is ready
    };

    updateDates();
    const interval = setInterval(updateDates, 24 * 60 * 60 * 1000); // Daily update
    return () => clearInterval(interval);
  }, []);
  const matchedCountry = updatedCountries.find(
    (countris) => countris.name === country.name
  );
  //******************* */ Date Every Months  *************************

  const RemovePerson = (index) => {
    if (workData.length > 1) {
      const newWorkerData = [...workData];
      newWorkerData.splice(index, 1);
      setWorkData(newWorkerData);
    }
  };

  const workerOnchangeHndle = (e, index) => {
    const { name, value } = e.target;
    const newData = [...workData];
    newData[index][name] = value;
    setWorkData(newData);
  };

  const DobHandlechange = (date, index) => {
    const newTravelers = [...workData];
    newTravelers[index].dob = date;
    setWorkData(newTravelers);
  };

  // handle phot passport other document function define here
  const handlePhotoChange = (index, e) => {
    const file = e.target.files[0];
    setPhotos((prevPhotos) => {
      const newPhotos = [...prevPhotos];
      newPhotos[index] = file;
      return newPhotos;
    });
  };

  const handlePassportChange = (index, e) => {
    const file = e.target.files[0];
    setPassport((prevPassport) => {
      const newPassport = [...prevPassport];
      newPassport[index] = file;
      return newPassport;
    });
  };

  const handleOtherDocChange = (index, e) => {
    const file = e.target.files[0];
    setOtherDoc((prevOtherDoc) => {
      const newOtherDoc = [...prevOtherDoc];
      newOtherDoc[index] = file;
      return newOtherDoc;
    });
  };

  // file updater********************************
  const passportUpdater = (comingPhoto) => {
    setWorkData((prev) => {
      const existingData = [...prev];
      const passportArray = existingData[comingPhoto.id].passport;

      // Check if the passport file already exists
      const passportExists = passportArray.some(
        (passport) =>
          passport.filename === comingPhoto.filename &&
          passport.originalname === comingPhoto.originalname
      );

      if (!passportExists) {
        passportArray.push({
          filename: comingPhoto.filename,
          originalname: comingPhoto.originalname,
        });
      }

      existingData[comingPhoto.id].passport = passportArray; // Ensure the state is updated
      return existingData;
    });
  };
  // photUpdater****************
  const photoUpdater = (comingPhoto) => {
    setWorkData((prev) => {
      const existingData = [...prev];
      const photoArray = existingData[comingPhoto.id].photos;

      // Check if the photo already exists
      const photoExists = photoArray.some(
        (photo) =>
          photo.filename === comingPhoto.filename &&
          photo.originalname === comingPhoto.originalname
      );

      if (!photoExists) {
        photoArray.push({
          filename: comingPhoto.filename,
          originalname: comingPhoto.originalname,
        });

        // Update the specific traveler's photos array
        existingData[comingPhoto.id].photos = photoArray;
      }

      return existingData; // Return the updated state
    });
  };
  // OtherDocUpdater****************
  const OtherDocUpdater = (comingPhoto) => {
    setWorkData((prev) => {
      const existingData = [...prev];
      const photoArray = existingData[comingPhoto.id].otherDoc;

      // Check if the photo already exists
      const photoExists = photoArray.some(
        (photo) =>
          photo.filename === comingPhoto.filename &&
          photo.originalname === comingPhoto.originalname
      );

      if (!photoExists) {
        photoArray.push({
          filename: comingPhoto.filename,
          originalname: comingPhoto.originalname,
        });

        // Update the specific traveler's photos array
        existingData[comingPhoto.id].otherDoc = photoArray;
      }

      return existingData; // Return the updated state
    });
  };

  // handle documents delete functions *******************

  const handlePassportDelete = (name) => {
    setWorkData((prev) => {
      const existingData = [...prev];
      const p = existingData.find((item) => {
        return item.passport.find((i) => {
          return i.originalname === name;
        });
      });

      if (!p) {
        return existingData; // If no matching item is found, return existing data without changes
      }

      const indexP = existingData.findIndex((ff) => {
        return ff === p;
      });
      const ss = p.passport.find((i) => {
        return i.originalname === name;
      });

      const filteredData = p.passport.filter((a) => {
        return a !== ss;
      });
      p.passport = filteredData;
      existingData[indexP] = p;
      return existingData;
    });
  };

  const handlePhotoDelete = (name) => {
    setWorkData((prev) => {
      const existingData = [...prev];
      const p = existingData.find((item) => {
        return item.photos.find((i) => {
          return i.originalname === name;
        });
      });

      if (!p) {
        return existingData; // If no matching item is found, return existing data without changes
      }

      const indexP = existingData.findIndex((ff) => {
        return ff === p;
      });
      const ss = p.photos.find((i) => {
        return i.originalname === name;
      });

      const filteredData = p.photos.filter((a) => {
        return a !== ss;
      });
      p.photos = filteredData;
      existingData[indexP] = p;
      return existingData;
    });
  };

  const handleOtherDelete = (name) => {
    setWorkData((prev) => {
      const existingData = [...prev];
      const p = existingData.find((item) => {
        return item.otherDoc.find((i) => {
          return i.originalname === name;
        });
      });

      if (!p) {
        return existingData; // If no matching item is found, return existing data without changes
      }

      const indexP = existingData.findIndex((ff) => {
        return ff === p;
      });
      const ss = p.otherDoc.find((i) => {
        return i.originalname === name;
      });

      const filteredData = p.otherDoc.filter((a) => {
        return a !== ss;
      });
      p.otherDoc = filteredData;
      existingData[indexP] = p;
      return existingData;
    });
  };

  // Define photo Passport Other Document functions*********************

  const handleApplicantPhotoUpload = async (index, e) => {
    e.preventDefault();
    ctx.setLoading(true);

    // Select the photos for a specific index
    const selectedPhotos = photos[index];

    if (!selectedPhotos) {
      alert("Please select a passport to upload.");
      ctx.setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("files1", selectedPhotos);

    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/applicant/upload/applicantPhoto",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();

      if (data.success) {
        ctx.setLoading(false);
        alert("Photos uploaded successfully!!!");

        // Clear the file input after successful upload
        if (photoInputRefs.current[index]) {
          photoInputRefs.current[index].value = "";
        }

        // Clear photos for that person in state after upload
        setPhotos((prevPhotos) => {
          const newPassport = [...prevPhotos];
          newPassport[index] = null;
          return newPassport;
        });

        // Update the photo in state if fileName and originalname are returned
        if (data.fileName && data.originalname) {
          photoUpdater({
            filename: data.fileName,
            originalname: data.originalname,
            id: index,
          });
        }
      } else {
        console.error("Upload failed:", data.msg || "Unknown error");
        ctx.setLoading(false);
      }
    } catch (error) {
      console.error("Error uploading photos:", error);
      ctx.setLoading(false);
    }
  };

  const appplicantHandleUploadPassport = async (index, e) => {
    e.preventDefault();
    ctx.setLoading(true);

    // Select the passport for a specific index
    const selectedPassport = passport[index];

    if (!selectedPassport) {
      alert("Please select a passport to upload.");
      ctx.setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("files5", selectedPassport);

    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/applicant/upload/applicantPassport",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();

      if (data.success) {
        ctx.setLoading(false);
        alert("Passport uploaded successfully!!!");

        // Clear the file input after successful upload
        if (passportInputRefs.current[index]) {
          passportInputRefs.current[index].value = "";
        }

        setPassport((prevPassport) => {
          const newPassport = [...prevPassport];
          newPassport[index] = null;
          return newPassport;
        });

        if (data.fileName && data.originalname) {
          passportUpdater({
            filename: data.fileName,
            originalname: data.originalname,
            id: index,
          });
        }
      } else {
        console.error("Upload failed:", data.msg || "Unknown error");
        ctx.setLoading(false);
      }
    } catch (error) {
      console.error("Error uploading passports:", error);
      ctx.setLoading(false);
    }
  };

  const applicantHandleUploadOtherDoc = async (index, e) => {
    e.preventDefault();
    ctx.setLoading(true);

    // Select other documents for a specific person at `index`
    const selectedOtherDoc = otherDoc[index]; // Assuming one file is uploaded at a time

    if (!selectedOtherDoc) {
      alert("Please select a document to upload.");
      ctx.setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("files6", selectedOtherDoc);

    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/applicant/upload/applicantOtherDoc",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();

      if (data.success) {
        alert("Document uploaded successfully!!!");

        // Clear the file input after successful upload
        if (otherDocInputRefs.current[index]) {
          otherDocInputRefs.current[index].value = "";
        }

        // Reset the specific document in state after upload
        setOtherDoc((prevWorkData) => {
          const newWorkData = [...prevWorkData];
          newWorkData[index].otherDoc = null;
          return newWorkData;
        });

        if (data.fileName && data.originalname) {
          OtherDocUpdater({
            filename: data.fileName,
            originalname: data.originalname,
            id: index,
          });
        }
      } else {
        console.error("Upload failed:", data.msg || "Unknown error");
        ctx.setLoading(false);
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
      ctx.setLoading(false);
    } finally {
      ctx.setLoading(false);
    }
  };

  // handle delete documents *************************

  const handleDeletePassport = async (nameToDelete) => {
    ctx.setLoading(true);
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/applicant/delete/documents",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fileName: nameToDelete }),
        }
      );
      const data = await response.json();
      if (data.success) {
        ctx.setLoading(false);
        handlePassportDelete(nameToDelete);
        alert("Deleted");
      } else {
        ctx.setLoading(false);
        alert("Failed to delete the passport from the database.");
      }
    } catch (error) {
      ctx.setLoading(false);
    }
  };
  const handlerDeletePhoto = async (nameToDelete) => {
    ctx.setLoading(true);
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/applicant/delete/documents",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fileName: nameToDelete }),
        }
      );
      const data = await response.json();
      if (data.success) {
        ctx.setLoading(false);
        handlePhotoDelete(nameToDelete);
        alert("Deleted");
      } else {
        ctx.setLoading(false);
        alert("Failed to delete the passport from the database.");
      }
    } catch (error) {
      ctx.setLoading(false);
    }
  };
  const handlerOtherDelete = async (nameToDelete) => {
    ctx.setLoading(true);
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/api/applicant/delete/documents",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fileName: nameToDelete }),
        }
      );
      const data = await response.json();
      if (data.success) {
        ctx.setLoading(false);
        handleOtherDelete(nameToDelete);
        alert("Deleted");
      } else {
        ctx.setLoading(false);
        alert("Failed to delete the passport from the database.");
      }
    } catch (error) {
      ctx.setLoading(false);
    }
  };
  // save all applicant data in mongoDb*******************************

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form data
    const newErrors = validationForApplicant(workData);
    setErrors(newErrors);

    // Check if there are any errors
    const hasErrors = newErrors.some(
      (personErrors) => Object.keys(personErrors).length > 0
    );
    const userId = localStorage.getItem("userId");
    if (!hasErrors) {
      const newTravelerIds = workData.map(() =>
        Math.random().toString(36).substring(7)
      );

      // Prepare form data
      const formData = workData.map((person, index) => ({
        name: person.name || "",
        lname: person.lname || "",
        email: person.email || "",
        MobileNO: person.MobileNO || "",
        dob: person.dob
          ? dayjs(person.dob).startOf("day").format("YYYY-MM-DD")
          : null,
        passportNo: person.passportNo || "",
        ApplyDate: person.ApplyDate || "",
        userId: userId,
        countryName: country.name,
        countryFees: country.price,
        id: newTravelerIds[index],
        photos: person.photos || [],
        passport: person.passport || [],
        otherDoc: person.otherDoc || [],
      }));
      try {
        const response = await fetch(
          "https://backend-visa2.vercel.app/api/applicant/saveworkData",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Something went wrong");
        }

        const result = await response.json();
        sessionStorage.setItem("workVisaId", JSON.stringify(newTravelerIds));       
        navigate("/applicantCheckOut",{state:{formData, country}});

        // Reset the form fields to empty values after success
        setWorkData({
          name: "",
          lname: "",
          email: "",
          MobileNO: "",
          dob: "",
          passportNo: "",
          photos: [],
          passport: [],
          otherDoc: [],
        });
      } catch (error) {
        console.error("Error saving data:", error.message);
      }
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const currentUrl = window.location.href;
  return (
    <>
      <div className="mainWraper main_WorkPermitDetails_page">
        <div className="ViaApply_Header trevellerHead">
          <Header />
          <Helmet>
            <meta charSet="utf-8" />
            <title>Applicant Details</title>
            <meta property="og:url" content={currentUrl} />
            <meta property="og:type" content="website" />
          </Helmet>
          {ctx.isLoading && <MyLoader />}
        </div>
        <div className=" main_pageLogin">
          <div className="stepper0144 stepper055 ">
            <Stepper size="sm">
              <Step
                indicator={
                  <StepIndicator
                    sx={{ backgroundColor: "red", color: "white" }}
                    variant="solid"
                  >
                    1
                  </StepIndicator>
                }
              >
                Step 1
              </Step>
              <Step
                indicator={<StepIndicator variant="solid">2</StepIndicator>}
              >
                Step 2
              </Step>
              <Step
                indicator={<StepIndicator variant="solid">3</StepIndicator>}
              >
                Step 3
              </Step>
            </Stepper>
          </div>
          <div className=" loginForm55">
            <div className="row loginform_style">
              <div className="col-md-8 col-lg-7 mb-4 custom-col">
                <div className="card ripe-malinka-gradient form-white">
                  <div className="card-body">
                    <form>
                      {Array.isArray(workData) &&
                        workData.map((worker, index) => (
                          <div className="addnew_workerForm" key={index}>
                            <h2 className="text-center font-up font-bold  travelHead">
                              Applicant Details
                              <span>{index + 1}</span>
                            </h2>
                            <div className="row ">
                              <div className="md-form mdForm_margin col-lg-6">
                                <input
                                  type="text"
                                  // id={`orangeForm-name-${index}`}
                                  className="form-control"
                                  placeholder=" First Name"
                                  value={worker.name}
                                  name="name"
                                  onChange={(e) =>
                                    workerOnchangeHndle(e, index)
                                  }
                                />
                                {errors[index] && errors[index].name && (
                                  <p
                                    className="style01"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {errors[index].name}
                                  </p>
                                )}
                              </div>
                              <div className="md-form mdForm_margin col-lg-6">
                                <input
                                  type="text"
                                  // id={`orangeForm-name-${index}`}
                                  className="form-control"
                                  placeholder=" Last Name"
                                  value={worker.lname}
                                  name="lname"
                                  onChange={(e) =>
                                    workerOnchangeHndle(e, index)
                                  }
                                />
                                {errors[index] && errors[index].lname && (
                                  <p
                                    className="style01"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {errors[index].lname}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="row ">
                              <div className="md-form mdForm_margin col-lg-6">
                                <input
                                  type="text"
                                  // id={`orangeForm-name-${index}`}
                                  className="form-control"
                                  placeholder=" Email"
                                  value={worker.email}
                                  name="email"
                                  onChange={(e) =>
                                    workerOnchangeHndle(e, index)
                                  }
                                />
                                {errors[index] && errors[index].email && (
                                  <p
                                    className="style01"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {errors[index].email}
                                  </p>
                                )}
                              </div>
                              <div className="md-form mdForm_margin col-lg-6">
                                <input
                                  type="text"
                                  // id={`orangeForm-name-${index}`}
                                  className="form-control"
                                  placeholder=" Contact No."
                                  value={worker.MobileNO}
                                  name="MobileNO"
                                  onChange={(e) =>
                                    workerOnchangeHndle(e, index)
                                  }
                                />
                                {errors[index] && errors[index].MobileNO && (
                                  <p
                                    className="style01"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {errors[index].MobileNO}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="row datePick0">
                              <div className="md-form mdForm_margin  col-lg-6 col-md-12">
                                <label className="doblable">DOB</label>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    key={worker.id}
                                    className="datetm4"
                                    value={
                                      worker.dob ? dayjs(worker.dob) : null
                                    }
                                    onChange={(date) =>
                                      DobHandlechange(date, index)
                                    }
                                    format="DD/MM/YYYY"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="DOB"
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                                {errors[index] && errors[index].dob && (
                                  <p
                                    className="style01"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {errors[index].dob}
                                  </p>
                                )}
                              </div>
                              <div className="workVisaPassportNo md-form mdForm_margin col-lg-6">
                                <input
                                  required
                                  type="text"
                                  // id={`orangeForm-name-${index}`}
                                  className="form-control"
                                  placeholder=" Passport No."
                                  value={worker.passportNo}
                                  name="passportNo"
                                  onChange={(e) =>
                                    workerOnchangeHndle(e, index)
                                  }
                                />
                                {errors[index] && errors[index].passportNo && (
                                  <p
                                    className="style01"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {errors[index].passportNo}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="row "></div>

                            <div className="passportName photoName">
                              <div className="uplodFile">
                                <Form.Group
                                  as={Col}
                                  controlId={`formFile-passport-${index}`}
                                  className="col_ChoosePhoto"
                                >
                                  <Form.Label className="lableOfLabele">
                                    Passport
                                  </Form.Label>
                                  <Form.Control
                                    onChange={(e) =>
                                      handlePassportChange(index, e)
                                    }
                                    name="file"
                                    type="file"
                                    size="lg"
                                    accept="application/pdf"
                                    className="uplodFileINput"
                                    ref={(el) =>
                                      (passportInputRefs.current[index] = el)
                                    }
                                  />
                                </Form.Group>
                                <button
                                  onClick={(e) =>
                                    appplicantHandleUploadPassport(index, e)
                                  }
                                  type="button"
                                  className="btn btn-outline-white waves-effect waves-light"
                                >
                                  Upload
                                </button>
                              </div>
                              <div className="getPhoto">
                                {worker.passport.length > 0 && (
                                  <>
                                    {worker.passport.map((name, ind) => (
                                      <p className="photoName_ooo" key={ind}>
                                        {name.filename && name.filename}{" "}
                                        <CiCircleRemove
                                          key={ind}
                                          onClick={() =>
                                            handleDeletePassport(
                                              name.originalname
                                            )
                                          }
                                        />
                                      </p>
                                    ))}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="photoName">
                              <div className="uplodFile">
                                <Form.Group
                                  as={Col}
                                  controlId={`formFile-photo-${index}`}
                                  className="col_ChoosePhoto"
                                >
                                  <Form.Label className="lableOfLabele">
                                    Photo
                                  </Form.Label>
                                  <Form.Control
                                    onChange={(e) =>
                                      handlePhotoChange(index, e)
                                    }
                                    name="file"
                                    type="file"
                                    size="lg"
                                    accept="image/png, image/jpeg"
                                    ref={(el) =>
                                      (photoInputRefs.current[index] = el)
                                    }
                                  />
                                </Form.Group>
                                <button
                                  type="button"
                                  className="btn btn-outline-white waves-effect waves-light"
                                  onClick={(e) =>
                                    handleApplicantPhotoUpload(index, e)
                                  }
                                >
                                  Upload
                                </button>
                              </div>
                              <div className="getPhoto">
                                {worker.photos.length > 0 && (
                                  <>
                                    {worker.photos.map((name, ind) => (
                                      <p className="photoName_ooo" key={ind}>
                                        {name.filename}{" "}
                                        <CiCircleRemove
                                          key={ind}
                                          onClick={() =>
                                            handlerDeletePhoto(
                                              name.originalname
                                            )
                                          }
                                        />
                                      </p>
                                    ))}
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="photoName">
                              <Col className="uplodFile buttonmanyDoc mb-3">
                                <Form.Group
                                  as={Col}
                                  className="col_ChoosePhoto"
                                  controlId={`formFile-other-${index}`}
                                >
                                  <Form.Label className="lableOfLabele">
                                    Experience Documents
                                  </Form.Label>

                                  <Form.Control
                                    ref={(el) =>
                                      (otherDocInputRefs.current[index] = el)
                                    }
                                    onChange={(e) =>
                                      handleOtherDocChange(index, e)
                                    }
                                    name="file"
                                    type="file"
                                    size="lg"
                                    accept="application/pdf"
                                  />
                                </Form.Group>
                                <button
                                  type="button"
                                  className="btn  waves-effect waves-light wave_light1"
                                  onClick={(e) =>
                                    applicantHandleUploadOtherDoc(index, e)
                                  }
                                >
                                  Upload
                                </button>
                              </Col>
                              <div className="getPhoto">
                                {worker.otherDoc.length > 0 && (
                                  <>
                                    {worker.otherDoc.map((name, ind) => (
                                      <p className="photoName_ooo" key={ind}>
                                        {name.filename}{" "}
                                        <CiCircleRemove
                                          key={ind}
                                          onClick={() =>
                                            handlerOtherDelete(
                                              name.originalname
                                            )
                                          }
                                        />
                                      </p>
                                    ))}
                                  </>
                                )}
                              </div>
                            </div>

                            {/* </div> */}
                            {index === workData.length - 1 && (
                              <div className=" addPerson01 ">
                                <button
                                  type="button"
                                  className="btn btn-outline-white waves-effect waves-light"
                                  onClick={AddPersson}
                                >
                                  Add Person <IoMdAddCircle />
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-white waves-effect waves-light"
                                  onClick={() => RemovePerson(index)}
                                >
                                  Remove
                                </button>
                                <button
                                  className="btn btn-outline-white waves-effect waves-light"
                                  onClick={handleSubmit}
                                >
                                  Next
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-4">
                <div className="card Amount_cardss">
                  <div className="card-content">
                    <h4>
                      Applicate Date:-{" "}
                      <span className="DestinatioCont">{applyDate}</span>
                    </h4>
                    <h4>
                      Destination:-{" "}
                      {country?.name ? (
                        <span className="DestinatioCont">{country?.name}</span>
                      ) : (
                        ""
                      )}
                    </h4>
                    <div className="price-I">
                      <h4>
                        Price:-{" "}
                        <span className="DestinatioCont">{country?.price}</span>
                      </h4>
                    </div>
                    <h4>
                      Next Filing Date                      
                        {loading ? (
                          <p>Loading...</p>
                        ) : matchedCountry ? (
                          <span className="DestinatioCont">
                            {formatDate(matchedCountry.dates) }
                          </span>
                        ) : (
                          <p>No matching country found</p>
                        )}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupVisaDetails;
